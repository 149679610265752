import InventoryView from '../views/InventoryView';
import IncomingView from '../views/IncomingView';
import OutgoingView from '../views/OutgoingView';
import LogsView from '../views/LogsView';
import LocationView from '../views/LocationView';

export const brandname_lg = { name: 'I', suffix: 'MS' }; //BRANDNAME TO BE DISPLAY WHEN ON LG MODE,MAX OF 10 CHARACTERS FOR NAME AND 5 FOR SUFFIX
export const brandname_sm = 'IMS'; //SHORTER NAME or acronym for BRANDNAME TO BE DISPLAY WHEN ON MOBILE SIZE, MAX OF 8 CHARACTERS ONLY
export const redirect_path = '/inventory'; //redirect path if successfully logged in
export const API = process.env.REACT_APP_API_URL; //api link

//https://ant.design/components/icon/ for icons
export const routes = [
	//array for routes
	{
		component: InventoryView,
		title: 'Inventory',
		icon: 'warehouse',
		view: true, //change to false if you dont want this route to be on sidebar menu
		path: '/inventory',
	},
	{
		component: IncomingView,
		title: 'Incoming',
		icon: 'plus-circle',
		view: true, //change to false if you dont want this route to be on sidebar menu
		path: '/incoming',
	},
	{
		component: OutgoingView,
		title: 'Outgoing',
		icon: 'minus-circle',
		view: true, //change to false if you dont want this route to be on sidebar menu
		path: '/outgoing',
	},
	{
		component: LocationView,
		title: 'Location',
		icon: 'map-marker',
		view: true, //change to false if you dont want this route to be on sidebar menu
		path: '/location',
	},
	{
		component: LogsView,
		title: 'Logs',
		icon: 'history',
		view: true, //change to false if you dont want this route to be on sidebar menu
		path: '/logs',
	},
];
