import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';

const LocationMap = props => {
	const [locations, setLocation] = useState(props.locations);
	const [isEditable, setEditable] = useState(false);

	const setNewPosition = (position, id) => {
		const locationIndex = locations.findIndex(loc => loc.id === id);
		const newLocations = [...locations];
		const { x: x_position, y: y_position } = position;
		if (locationIndex !== -1) {
			const location = {
				x_position,
				y_position,
			};
			newLocations[locationIndex] = { ...newLocations[locationIndex], ...location };
			setLocation(newLocations);
		}
	};

	const setNewSize = (position, { width, height }, id) => {
		const locationIndex = locations.findIndex(loc => loc.id === id);
		const newLocations = [...locations];
		const { x: x_position, y: y_position } = position;
		if (locationIndex !== -1) {
			const location = {
				x_position,
				y_position,
				width,
				height,
			};
			newLocations[locationIndex] = { ...newLocations[locationIndex], ...location };
			setLocation(newLocations);
		}
	};

	useEffect(() => {
		setLocation(props.locations);
	}, [props.locations, isEditable]);
	return (
		<>
			<h2>LOCATION MAP</h2>
			<Button
				style={{ marginRight: 10 }}
				className="bl-cl"
				onClick={() => setEditable(val => !val)}
				icon={isEditable ? 'close-circle' : 'edit'}
			>
				{isEditable ? 'CANCEL EDIT' : 'EDIT LOCATION MAP'}
			</Button>
			{isEditable && (
				<Button
					style={{ marginRight: 10 }}
					icon="save"
					className="bl-cl"
					onClick={() => props.updateMapPosition(locations, setEditable)}
				>
					SAVE
				</Button>
			)}

			<div
				style={{
					width: '100%',
					height: '100vh',
					marginTop: 30,
					overflow: 'auto',
					backgroundColor: isEditable ? '#F1F1F1' : '#fff',
					border: '1px solid gray',
				}}
			>
				{locations
					.filter(location => location.showOnMap)
					.map(location => (
						<Rnd
							key={location.id}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: '#3498db',
								color: '#fff',
							}}
							position={{ x: location.x_position, y: location.y_position }}
							size={{ width: location.width, height: location.height }}
							minWidth={50}
							minHeight={50}
							onDragStop={(e, data) => setNewPosition(data, location.id)}
							onResizeStop={(e, direction, { style }, delta, position) =>
								setNewSize(position, style, location.id)
							}
							onResize={e => e.stopPropagation()}
							bounds="parent"
							enableResizing={{
								top: isEditable,
								left: isEditable,
								right: isEditable,
								bottom: isEditable,
								bottomLeft: isEditable,
								bottomRight: isEditable,
								topLeft: isEditable,
								topRight: isEditable,
							}}
							disableDragging={!isEditable}
						>
							{location.label}
						</Rnd>
					))}
			</div>
		</>
	);
};

LocationMap.propTypes = {
	locations: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default LocationMap;
