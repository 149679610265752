import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid #d9d9d9;
	padding: 10px 0;
`;

const FormButton = ({ handleSubmit, handleReset, isSubmitting }) => {
	return (
		<ButtonWrapper>
			<Button style={{ margin: '0 5px' }} onClick={handleReset}>
				Reset
			</Button>
			<Button disabled={isSubmitting} type="primary" onClick={handleSubmit}>
				Submit
			</Button>
		</ButtonWrapper>
	);
};
FormButton.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	handleReset: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
};
export default FormButton;
