import React, { Fragment, lazy, Suspense, useContext, useEffect, useState } from 'react';
import Loader from '../components/Loader/Loader';
import { InvContext } from '../context/InventoryContext/InventoryContext';
import { Utils } from '../context/UtilsContext';
import Layout from '../layout/Layout';
import { Button, Tooltip, Input, Icon } from 'antd';

const InventoryTable = lazy(() => import('../components/Inventory/InventoryTable'));
const InventoryModal = lazy(() => import('../components/Inventory/InventoryModal'));

const IncomingModal = lazy(() => import('../components/Incoming/IncomingModal'));
const OutgoingModal = lazy(() => import('../components/Outgoing/OutgoingModal'));
const LocationModal = lazy(() => import('../components/Location/ItemLocationModal'));

const InventoryView = props => {
	const { setLoading } = useContext(Utils);
	const {
		inv,
		getInventory,
		deleteInventory,
		getLocations,
		addItemLocation,
		deleteItemLocation,
		exportInventory,
	} = useContext(InvContext);
	const [search, setSearch] = useState('');

	useEffect(() => {
		getInventory(setLoading);
	}, []);

	const [modalConfig, setModalConfig] = useState({
		visible: false,
		data: {},
		type: '',
	});

	const openModal = (data, type) => {
		setModalConfig({
			visible: true,
			data,
			type,
		});
	};

	const closeModal = () => {
		setModalConfig({
			visible: false,
			data: {},
		});
	};

	const { inventoryList } = inv;

	const filteredSearch = inventoryList.filter(inv => {
		return (
			inv.mspecs.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
			inv.itemdesc.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
			inv.partnum.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
			inv.code.toUpperCase().indexOf(search.toUpperCase()) !== -1
		);
	});

	return (
		<Fragment>
			<br />
			<h2>Inventory list</h2>
			<div
				style={{
					display: 'flex',
					justifyContent: 'spacebetween',
					width: '100%',
					margin: '5px 0 5px 0',
				}}
			>
				<div style={{ flexGrow: 5 }}>
					<Button
						onClick={() => openModal({}, 'inventory')}
						icon="form"
						style={{ marginRight: 5 }}
						className="bl-cl"
					>
						Add Item
					</Button>

					<Tooltip title="Export to CSV" placement="right">
						<Button
							onClick={() => exportInventory(setLoading)}
							shape="circle"
							className="bl-cl"
							style={{ marginRight: 5 }}
							icon="file-excel"
						></Button>
					</Tooltip>

					<Tooltip title="Refresh Content" placement="right">
						<Button
							onClick={() => getInventory(setLoading)}
							icon="sync"
							shape="circle"
							tyle={{ marginRight: 5 }}
							className="bl-cl"
						></Button>
					</Tooltip>
				</div>
				<div style={{ flexGrow: 1, textAlign: 'right' }}>
					<Input
						onChange={e => setSearch(e.target.value)}
						placeholder="Search"
						value={search}
						suffix={<Icon type="search" />}
						style={{ width: '100%' }}
					/>
				</div>
			</div>
			<Suspense fallback={<Loader />}>
				<InventoryTable
					openModal={openModal}
					inventoryList={filteredSearch}
					deleteInventory={deleteInventory}
					setLoading={setLoading}
				/>

				<Suspense fallback={<div>Loading Modal...</div>}>
					{modalConfig.type === 'inventory' && (
						<InventoryModal
							closeModal={closeModal}
							data={modalConfig.data}
							visible={modalConfig.visible}
						/>
					)}

					{modalConfig.type === 'location' && (
						<LocationModal
							closeModal={closeModal}
							data={modalConfig.data}
							visible={modalConfig.visible}
							getLocations={getLocations}
							locations={inv.locations}
							setLoading={setLoading}
							addLocation={addItemLocation}
							deleteLocation={deleteItemLocation}
						/>
					)}

					{modalConfig.type === 'incoming' && (
						<IncomingModal
							closeModal={closeModal}
							data={modalConfig.data}
							visible={modalConfig.visible}
						/>
					)}

					{modalConfig.type === 'outgoing' && (
						<OutgoingModal
							closeModal={closeModal}
							data={modalConfig.data}
							visible={modalConfig.visible}
						/>
					)}
				</Suspense>
			</Suspense>
		</Fragment>
	);
};

export default Layout(InventoryView);
