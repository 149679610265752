import React, { createContext, useReducer } from 'react';
import moment from 'moment';
import axios from 'axios';

import {
	reducer,
	GET_INVENTORYOPTIONS,
	GET_INVENTORY,
	ADD_INVENTORY,
	EDIT_INVENTORY,
	DELETE_INVENTORY,
	GET_JOBORDEROPTIONS,
	GET_INCOMING,
	DELETE_INCOMING,
	GET_OUTGOING,
	DELETE_OUTGOING,
	GET_LOCATIONS,
	ADD_LOCATION,
	UPDATE_LOCATION,
	DELETE_LOCATION,
} from './InventoryReducer';

import { API } from '../../config/config';
import { headers } from '../../config/token';
import { displayErrors, displayNotification } from '../../config/display';

export const InvContext = createContext({});
const InventoryContext = ({ children }) => {
	const initialValues = {
		inventoryList: [],
		masterlistOpt: [],
		unitsOption: [],
		joborderOpt: [],
		incomingDetails: {
			list: [],
			length: 0,
		},
		outgoingDetails: {
			list: [],
			length: 0,
		},
		locations: [],
	};

	const [inv, dispatch] = useReducer(reducer, initialValues);

	const getMasterlistOpt = setLoading => {
		const masterlist = axios.get(`${API}inventory/option/masterlist`, headers());
		const units = axios.get(`${API}options/units`);

		setLoading(true);

		axios
			.all([masterlist, units])
			.then(
				axios.spread((masterlistRes, unitsRes) => {
					const { masterlistOpt } = masterlistRes.data;
					const { unitsOption } = unitsRes.data;

					dispatch({
						type: GET_INVENTORYOPTIONS,
						payload: {
							masterlistOpt,
							unitsOption,
						},
					});
				})
			)
			.catch(err => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const getInventory = setLoading => {
		setLoading(true);

		axios
			.get(API + `inventory`, headers())
			.then(res => {
				const { inventoryList } = res.data;

				dispatch({
					type: GET_INVENTORY,
					payload: {
						inventoryList,
					},
				});
			})
			.catch(err => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const addInventory = (values, resetForm, setSubmitting) => {
		axios
			.post(API + `inventory`, values, headers())
			.then(res => {
				const { newItem, message } = res.data;

				dispatch({
					type: ADD_INVENTORY,
					payload: {
						newItem,
					},
				});
				resetForm();
				displayNotification('success', message);
			})
			.catch(err => displayErrors(err))
			.finally(() => setSubmitting(false));
	};

	const editInventory = (values, closeModal, setSubmitting) => {
		axios
			.put(`${API}inventory/${values.id}`, values, headers())
			.then(res => {
				const { newItem, message } = res.data;

				dispatch({
					type: EDIT_INVENTORY,
					payload: {
						newItem,
						id: values.id,
					},
				});
				displayNotification('success', message);
				closeModal();
			})
			.catch(err => displayErrors(err))
			.finally(() => setSubmitting(false));
	};

	const deleteInventory = (deletedItem, setLoading) => {
		axios
			.delete(API + `inventory/${deletedItem.id}`, headers())
			.then(res => {
				const { message } = res.data;

				dispatch({
					type: DELETE_INVENTORY,
					payload: {
						deletedId: deletedItem.id,
						deletedItem,
					},
				});
				displayNotification('success', message);
			})
			.catch(err => displayErrors(err))
			.finally(() => setLoading(true));
	};

	const getJobOrderOptions = () => {
		axios
			.get(API + `inventory/option/joborder`, headers())
			.then(res => {
				const { jobOrder } = res.data;

				dispatch({
					type: GET_JOBORDEROPTIONS,
					payload: {
						jobOrder,
					},
				});
			})
			.catch(err => displayErrors(err));
	};

	const addIncoming = (values, resetForm, setSubmitting) => {
		axios
			.post(API + `inventory/incoming`, values, headers())
			.then(res => {
				const { message, newItem } = res.data;

				dispatch({
					type: EDIT_INVENTORY,
					payload: {
						newItem,
						id: values.id,
					},
				});
				resetForm();
				displayNotification('success', message);
			})
			.catch(err => displayErrors(err))
			.finally(() => setSubmitting(false));
	};

	const getIncoming = (setLoading, paginate, filter) => {
		setLoading(true);
		const { search, sort, start, end } = filter;
		const { page, pageSize } = paginate;
		let params = `?page=${page}&pageSize=${pageSize}&sort=${sort}&start=${start}&end=${end}`;

		if (search && search !== '' && search.trim() !== '') {
			params += `&search=${search}`;
		}

		axios
			.get(API + `inventory/incoming${params}`, headers())
			.then(res => {
				const { incomingDetails } = res.data;
				dispatch({
					type: GET_INCOMING,
					payload: {
						incomingDetails,
					},
				});
			})
			.catch(err => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const deleteIncoming = (id, setLoading) => {
		setLoading(true);
		axios
			.delete(API + `inventory/incoming/${id}`, headers())
			.then(res => {
				const { message } = res.data;
				dispatch({
					type: DELETE_INCOMING,
					payload: {
						id,
					},
				});
				displayNotification('success', message);
			})
			.catch(err => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const addOutgoing = (values, resetForm, closeModal, setSubmitting) => {
		axios
			.post(API + `inventory/outgoing`, values, headers())
			.then(res => {
				const { message, newItem } = res.data;
				if (newItem && newItem.quantity > 0) {
					dispatch({
						type: EDIT_INVENTORY,
						payload: {
							newItem,
							id: values.id,
						},
					});

					resetForm();
				} else {
					dispatch({
						type: DELETE_INVENTORY,
						payload: {
							deletedId: newItem.id,
						},
					});
					closeModal();
				}

				displayNotification('success', message);
			})
			.catch(err => displayErrors(err))
			.finally(() => setSubmitting(false));
	};

	const getOutgoing = (setLoading, paginate, filter) => {
		setLoading(true);
		const { search, sort, start, end } = filter;
		const { page, pageSize } = paginate;
		let params = `?page=${page}&pageSize=${pageSize}&sort=${sort}&start=${start}&end=${end}`;

		if (search && search !== '' && search.trim() !== '') {
			params += `&search=${search}`;
		}

		axios
			.get(API + `inventory/outgoing${params}`, headers())
			.then(res => {
				const { outgoingDetails } = res.data;
				dispatch({
					type: GET_OUTGOING,
					payload: {
						outgoingDetails,
					},
				});
			})
			.catch(err => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const deleteOutgoing = (id, setLoading) => {
		setLoading(true);
		axios
			.delete(API + `inventory/outgoing/${id}`, headers())
			.then(res => {
				const { message } = res.data;
				dispatch({
					type: DELETE_OUTGOING,
					payload: {
						id,
					},
				});
				displayNotification('success', message);
			})
			.catch(err => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const getLocations = () =>
		axios
			.get(API + `inventory/locations`, headers())
			.then(res => {
				const { locations } = res.data;
				dispatch({
					type: GET_LOCATIONS,
					payload: {
						locations,
					},
				});
				return locations;
			})
			.catch(err => displayErrors(err));

	const addItemLocation = (id, locId, setLoading) => {
		axios
			.post(API + `inventory/item/locations`, { id, locId }, headers())
			.then(res => {
				const { message, newItem } = res.data;
				dispatch({
					type: EDIT_INVENTORY,
					payload: {
						newItem,
						id: id,
					},
				});
				displayNotification('success', message);
			})
			.catch(err => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const deleteItemLocation = (id, locId, setLoading) => {
		axios
			.delete(API + `inventory/item/locations/${id}?locId=${locId}`, headers())
			.then(res => {
				const { message, newItem } = res.data;

				dispatch({
					type: EDIT_INVENTORY,
					payload: {
						newItem,
						id: id,
					},
				});
				displayNotification('success', message);
			})
			.catch(err => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const exportInventory = setLoading => {
		setLoading(true);
		const dateNow = moment().format('Y_M_D');
		axios({
			url: `${API}inventory/export`,
			method: 'get',
			responseType: 'blob',
			...headers(),
		})
			.then(res => {
				displayNotification('success', 'File successfully generated');
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('target', '_blank');
				link.setAttribute('download', `Inventory_as_of_${dateNow}.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.parentElement.removeChild(link);
			})
			.catch(err => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const addLocation = async (values, setLoading, resetForm, setSubmitting) => {
		setLoading(true);
		try {
			const result = await axios.post(`${API}inventory/locations`, values, headers());
			const { message, newLocation } = result.data;
			dispatch({
				type: ADD_LOCATION,
				payload: {
					newLocation,
				},
			});
			displayNotification('success', message);
			resetForm();
		} catch (error) {
			displayErrors(error);
		} finally {
			setSubmitting(false);
			setLoading(false);
		}
	};

	const updateLocation = async (values, setLoading, closeModal) => {
		setLoading(true);
		try {
			const result = await axios.put(`${API}inventory/locations/${values.id}`, values, headers());

			const { message, newLocation } = result.data;
			dispatch({
				type: UPDATE_LOCATION,
				payload: {
					newLocation,
				},
			});
			displayNotification('success', message);
			closeModal();
		} catch (error) {
			displayErrors(error);
		} finally {
			setLoading(false);
		}
	};

	const deleteLocation = async (id, setLoading) => {
		setLoading(true);
		try {
			const result = await axios.delete(`${API}inventory/locations/${id}`, headers());

			const { message } = result.data;
			dispatch({
				type: DELETE_LOCATION,
				payload: {
					id,
				},
			});
			displayNotification('success', message);
		} catch (error) {
			displayErrors(error);
		} finally {
			setLoading(false);
		}
	};

	const updateLocationMapPosition = async (values, setEditable, setLoading) => {
		setLoading(true);
		try {
			const result = await axios.post(
				`${API}inventory/locations/map`,
				{ locations: values },
				headers()
			);
			const { message, locations } = result.data;
			dispatch({
				type: GET_LOCATIONS,
				payload: {
					locations,
				},
			});
			displayNotification('success', message);
			setEditable(false);
		} catch (error) {
			displayErrors(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<InvContext.Provider
			value={{
				inv,
				getInventory,
				addInventory,
				editInventory,
				deleteInventory,
				getMasterlistOpt,
				getJobOrderOptions,
				addIncoming,
				addOutgoing,
				getIncoming,
				deleteIncoming,
				getOutgoing,
				deleteOutgoing,
				getLocations,
				addItemLocation,
				deleteItemLocation,
				exportInventory,
				addLocation,
				updateLocation,
				deleteLocation,
				updateLocationMapPosition,
			}}
		>
			{children}
		</InvContext.Provider>
	);
};

export default InventoryContext;
