import React, { useContext, useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import styled from 'styled-components';
import { Input, Button } from 'antd';

import { InvContext } from '../context/InventoryContext/InventoryContext';
import { Utils } from '../context/UtilsContext';
import LocationTable from '../components/Location/LocationTable';
import LocationModal from '../components/Location/LocationModal';
import LocationMap from '../components/Location/LocationMap';
import Confirm from '../components/Confirm';

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0;
	flex-wrap: wrap;
`;

const LocationView = () => {
	const {
		inv: { locations },
		getLocations,
		deleteLocation,
		updateLocationMapPosition,
	} = useContext(InvContext);
	const { setLoading } = useContext(Utils);
	const [modalData, setModalData] = useState({ visible: false, data: {} });
	const [search, setSearch] = useState('');

	const openModal = data => setModalData({ visible: true, data });
	const closeModal = () => setModalData({ visible: false, data: {} });
	const deleteRecord = id => Confirm(deleteLocation, null, id, setLoading);
	const updateMapPosition = (newLocations, setEditable) =>
		Confirm(updateLocationMapPosition, null, newLocations, setEditable, setLoading);

	useEffect(() => {
		if (locations.length < 1) {
			setLoading(true);
			getLocations().finally(() => setLoading(false));
		}
	}, []);

	const filteredLocation = locations.filter(
		location => location.label.toUpperCase().indexOf(search.toUpperCase()) !== -1
	);
	return (
		<>
			<br />
			<h2>LOCATIONS LIST</h2>
			<Container>
				<Button onClick={() => openModal({})} size="large" icon="form" className="bl-cl">
					Add Location
				</Button>
				<Input
					onChange={e => setSearch(e.target.value)}
					placeholder="Search records"
					size="large"
					value={search}
					style={{ width: '100%', maxWidth: 400 }}
				/>
			</Container>
			<LocationTable
				deleteRecord={deleteRecord}
				openModal={openModal}
				locations={filteredLocation}
			/>
			<LocationModal visible={modalData.visible} data={modalData.data} closeModal={closeModal} />
			{locations.filter(location => location.showOnMap).length > 0 && (
				<>
					<LocationMap updateMapPosition={updateMapPosition} locations={locations} />
				</>
			)}
		</>
	);
};

export default Layout(LocationView);
