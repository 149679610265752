import React,
{
  Fragment,
  lazy,
  Suspense,
  useState,
  useContext,
  useEffect
} from 'react';
import { InvContext } from '../context/InventoryContext/InventoryContext';
import { Utils } from '../context/UtilsContext';
import Layout from '../layout/Layout';
import moment from 'moment';

const IncomingOutgoingTable = lazy(() => import('../components/Tables/IncomingOutgoingTable'));
const IncomingOutgoingFilter = lazy(() => import('../components/Filter/IncomingOutgoingFilter'));

const OutgoingView = props => {

  const defaultFilterVal = { search: '', start: moment().format('YYYY-MM-01'), end: moment().endOf('month').format('YYYY-MM-DD'), sort: 'desc' };

  const [paginate, setPaginate] = useState({ pageSize: 10, page: 1 });// pagination 
  const [filter, setFilter] = useState(defaultFilterVal);
  const { inv: { outgoingDetails }, getOutgoing, deleteOutgoing } = useContext(InvContext);
  const { setLoading } = useContext(Utils);
  useEffect(() => {
    getOutgoing(setLoading, paginate, filter);
  }, []);

  const onResetFilter = () => {
    setPaginate({ pageSize: 10, page: 1 });
    setFilter(defaultFilterVal);
    getOutgoing(setLoading, { pageSize: 10, page: 1 }, defaultFilterVal);
  }

  const onFilter = () => {
    setPaginate({ pageSize: 10, page: 1 });
    getOutgoing(setLoading, { pageSize: 10, page: 1 }, filter);
  }

  const onPageChange = page => {
    setPaginate({ ...paginate, page: page })
    getOutgoing(setLoading, { pageSize: paginate.pageSize, page }, filter);
  }

  const onSizeChange = (page, pageSize) => {
    setPaginate({ ...paginate, pageSize });
    getOutgoing(setLoading, { pageSize, page }, filter);
  }

  return (
    <Fragment>
      <br />
      <h2>OUTGOING LIST</h2>
      <Suspense fallback={<div>Loading Content...</div>}>
        <IncomingOutgoingFilter
          filter={filter}
          setFilter={setFilter}
          onFilter={onFilter}
          onResetFilter={onResetFilter}
        />

        <IncomingOutgoingTable
          outgoingDetails={outgoingDetails}
          paginate={paginate}
          onSizeChange={onSizeChange}
          onPageChange={onPageChange}
          deleteRecord={deleteOutgoing}
          setLoading={setLoading}
          type="outgoing"
        />
      </Suspense>
    </Fragment>
  )
}

export default Layout(OutgoingView)
