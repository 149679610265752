import React, { Fragment, lazy, Suspense, useState, useContext, useEffect } from 'react';
import { InvContext } from '../context/InventoryContext/InventoryContext';
import { Utils } from '../context/UtilsContext';
import Layout from '../layout/Layout';
import moment from 'moment';

const IncomingOutgoingTable = lazy(() => import('../components/Tables/IncomingOutgoingTable'));
const IncomingOutgoingFilter = lazy(() => import('../components/Filter/IncomingOutgoingFilter'));

const IncomingView = () => {
	const defaultFilterVal = {
		search: '',
		start: moment().format('YYYY-MM-01'),
		end: moment().endOf('month').format('YYYY-MM-DD'),
		sort: 'desc',
	};
	const [paginate, setPaginate] = useState({ pageSize: 10, page: 1 }); // pagination
	const [filter, setFilter] = useState(defaultFilterVal);
	const {
		inv: { incomingDetails },
		getIncoming,
		deleteIncoming,
	} = useContext(InvContext);
	const { setLoading } = useContext(Utils);
	useEffect(() => {
		getIncoming(setLoading, paginate, filter);
	}, []);

	const onResetFilter = () => {
		setPaginate({ pageSize: 10, page: 1 });
		setFilter(defaultFilterVal);
		getIncoming(setLoading, { pageSize: 10, page: 1 }, defaultFilterVal);
	};

	const onFilter = () => {
		setPaginate({ pageSize: 10, page: 1 });
		getIncoming(setLoading, { pageSize: 10, page: 1 }, filter);
	};

	const onPageChange = page => {
		setPaginate({ ...paginate, page: page });
		getIncoming(setLoading, { pageSize: paginate.pageSize, page }, filter);
	};

	const onSizeChange = (page, pageSize) => {
		setPaginate({ ...paginate, pageSize });
		getIncoming(setLoading, { pageSize, page }, filter);
	};

	return (
		<Fragment>
			<br />
			<h2>INCOMING LIST</h2>
			<Suspense fallback={<div>Loading Content...</div>}>
				<IncomingOutgoingFilter
					filter={filter}
					setFilter={setFilter}
					onFilter={onFilter}
					onResetFilter={onResetFilter}
				/>

				<IncomingOutgoingTable
					incomingDetails={incomingDetails}
					paginate={paginate}
					onSizeChange={onSizeChange}
					onPageChange={onPageChange}
					deleteRecord={deleteIncoming}
					setLoading={setLoading}
					type='incoming'
				/>
			</Suspense>
		</Fragment>
	);
};

export default Layout(IncomingView);
