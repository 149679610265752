export const GET_INVENTORY = 'GET_INVENTORY';
export const ADD_INVENTORY = 'ADD_INVENTORY';
export const EDIT_INVENTORY = 'EDIT_INVENTORY';
export const DELETE_INVENTORY = 'DELETE_INVENTORY';
export const GET_INVENTORYOPTIONS = 'GET_INVENTORYOPTIONS';
export const GET_JOBORDEROPTIONS = 'GET_JOBORDEROPTIONS';
export const DELETE_INCOMING = 'DELETE_INCOMING';
export const GET_OUTGOING = 'GET_OUTGOING';
export const DELETE_OUTGOING = 'DELETE_OUTGOING';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const ADD_LOCATION = 'ADD_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';

//incoming
export const GET_INCOMING = 'GET_INCOMING';

export const reducer = (state, action) => {
	const { payload, type } = action;

	switch (type) {
		case GET_INVENTORY:
			return {
				...state,
				inventoryList: payload.inventoryList,
			};
		case GET_INVENTORYOPTIONS:
			return {
				...state,
				masterlistOpt: payload.masterlistOpt,
				unitsOption: payload.unitsOption,
			};
		case ADD_INVENTORY:
			return {
				...state,
				inventoryList: [payload.newItem, ...state.inventoryList],
			};
		case EDIT_INVENTORY:
			return {
				...state,
				inventoryList: [
					...state.inventoryList.slice(
						0,
						state.inventoryList.findIndex(d => d.id === payload.newItem.id)
					),
					{ ...action.payload.newItem },
					...state.inventoryList.slice(
						state.inventoryList.findIndex(d => d.id === payload.newItem.id) + 1
					),
				],
			};
		case DELETE_INVENTORY:
			return {
				...state,
				inventoryList: state.inventoryList.filter(inv => inv.id !== payload.deletedId),
			};
		case GET_JOBORDEROPTIONS:
			return {
				...state,
				joborderOpt: payload.jobOrder,
			};
		case GET_INCOMING:
			return {
				...state,
				incomingDetails: { ...payload.incomingDetails },
			};
		case DELETE_INCOMING:
			return {
				...state,
				incomingDetails: {
					list: state.incomingDetails.list.filter(record => record.id !== payload.id),
					length: state.incomingDetails.length - 1,
				},
			};
		case GET_OUTGOING:
			return {
				...state,
				outgoingDetails: { ...payload.outgoingDetails },
			};
		case DELETE_OUTGOING:
			return {
				...state,
				outgoingDetails: {
					list: state.outgoingDetails.list.filter(record => record.id !== payload.id),
					length: state.outgoingDetails.length - 1,
				},
			};
		case GET_LOCATIONS:
			return {
				...state,
				locations: payload.locations,
			};
		case ADD_LOCATION:
			return {
				...state,
				locations: [{ ...payload.newLocation }, ...state.locations],
			};
		case UPDATE_LOCATION:
			return {
				...state,
				locations: [
					...state.locations.slice(
						0,
						state.locations.findIndex(d => d.id === payload.newLocation.id)
					),
					payload.newLocation,
					...state.locations.slice(
						state.locations.findIndex(d => d.id === payload.newLocation.id) + 1
					),
				],
			};
		case DELETE_LOCATION:
			return {
				...state,
				locations: state.locations.filter(location => location.id !== payload.id),
			};
		default:
			return state;
	}
};
