import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Table } from 'antd';

const LocationTable = ({ locations, openModal, deleteRecord }) => {
	const columns = [
		{
			key: 'deleteBtn',
			width: '2%',
			align: 'center',
			render: record => (
				<Tooltip>
					<Button icon="delete" className="red-cl" onClick={() => deleteRecord(record.id)} />
				</Tooltip>
			),
		},
		{
			key: 'editBtn',
			width: '2%',
			align: 'center',
			render: record => (
				<Tooltip>
					<Button icon="edit" className="bl-cl" onClick={() => openModal(record)} />
				</Tooltip>
			),
		},
		{
			key: 'label',
			title: 'Location',
			dataIndex: 'label',
			align: 'center',
			sorter: (a, b) => {
				const A = a.label || '';
				const B = b.label || '';
				return A.localeCompare(B);
			},
		},
		{
			key: 'showOnMap',
			title: 'Show on map',
			render: record => (record.showOnMap ? 'YES' : 'NO'),
			align: 'center',
			sorter: (a, b) => {
				const A = a.showOnMap || '';
				const B = b.showOnMap || '';
				return A.localeCompare(B);
			},
		},
		{
			key: 'itemsCOunt',
			title: 'Item/s count',
			dataIndex: 'itemsCount',
			align: 'center',
		},
		{
			key: 'map',
			title: 'Map Position (X, Y)',
			align: 'center',
			render: record => `${record.x_position.toFixed(0)} / ${record.y_position.toFixed(0)}`,
		},
		{
			key: 'size',
			title: 'Size on map ( W x H )',
			render: record => `${record.width} x ${record.height}`,
			align: 'center',
		},
	];

	return (
		<Table
			dataSource={locations}
			columns={columns}
			rowKey="id"
			bodyStyle={{
				overflowX: 'auto',
			}}
			pagination={{
				showSizeChanger: false,
				pageSize: 5,
				showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
			}}
		/>
	);
};

LocationTable.propTypes = {
	locations: PropTypes.arrayOf(PropTypes.object).isRequired,
	openModal: PropTypes.func.isRequired,
	deleteRecord: PropTypes.func.isRequired,
};
export default LocationTable;
